import React,{useState,useEffect,useContext} from 'react'
import './Recharge.css'
import store from '../../../mobx/store';
import { autorun, runInAction } from 'mobx';
import axios from 'axios';
//npm install react-transition-group过渡动画1/2
import { TransitionGroup, CSSTransition } from "react-transition-group";
import '../../../transitions/transitions.css'; // 存放过渡动画的样式
import { WechatOutlined,AlipayCircleOutlined } from '@ant-design/icons';
import axiosurl from '../../../axiosurl/axiosurl'
import { QRCodeCanvas } from 'qrcode.react';
import SocketContext from '../../../socketio/Socketio';
export default function Recharge() {
   const newSocket = useContext(SocketContext);
   const [socket, setSocket] = useState(null);
    const [rechargeisshow, setrechargeisshow] = useState(false);//控制用户充值页显示和隐藏
    const [rechargetoisshow, setrechargetoisshow] = useState(false);//用于控制点击topbar后显示用户登录式样还是注册登录等式样
    const [slideTo, setSlideTo] = useState('to-left');//控制模型选择的滑块
    const [vipaddisshow, setvipaddisshow] = useState(true);//控制显示会员还是加量包
    const [userpayselect, setuserpayselect] = useState(0);//点了第几个
    const [userpayisshow, setuserpayisshow] = useState(false);//控制二维码页面的显示隐藏
    const [qrCodeUrl, setQrCodeUrl] = useState('');//保存返回的二维码
    const [paymentmethod, setpaymentmethod] = useState('支付宝');
    const [promptspan, setpromptspan] = useState('');//提示盒子文本
    const [promptisshow, setpromptisshow] = useState(false);//提示盒子隐藏和显示
    const [rechargeuservip, setrechargeuservip] = useState([
      {id:0,money:'￥29.9',spanone:'月度高级会员',spantwo:'￥29.9元每月',spanthree:'每日仅0.99元',spanfour:'全站功能解锁',spanfive:'赠送AI算力200积分',spansix:'多任务同时生成',spanseven:'生成无次数限制',spaneight:'PC+手机+iPad多端通用'},
      {id:1,money:'￥79.9',spanone:'季度高级会员',spantwo:'￥79.9元每季',spanthree:'每日仅0.88元',spanfour:'全站功能解锁',spanfive:'赠送AI算力600积分',spansix:'多任务同时生成',spanseven:'生成无次数限制',spaneight:'PC+手机+iPad多端通用'},
      {id:2,money:'￥289.9',spanone:'年度高级会员',spantwo:'￥289.9元每年',spanthree:'每日仅0.79元',spanfour:'全站功能解锁',spanfive:'赠送AI算力2400积分',spansix:'多任务同时生成',spanseven:'生成无次数限制',spaneight:'PC+手机+iPad多端通用'},
    ]);
    const [rechargeuseradd, setrechargeuseradd] = useState([
      {id:0,money:'￥29.9',spanone:'积分包236积分',spantwo:'￥29.9元/236积分',spanthree:'每积分仅0.126元',spanfour:'充值AI通用积分236积分',spanfive:'全站功能通用',spansix:'充值积分永不过期',spanseven:'多任务同时生成',spaneight:'PC+手机+iPad多端通用'},
      {id:1,money:'￥79.9',spanone:'积分包688积分',spantwo:'￥79.9元/688积分',spanthree:'每积分仅0.116元',spanfour:'充值AI通用积分236积分',spanfive:'全站功能通用',spansix:'充值积分永不过期',spanseven:'多任务同时生成',spaneight:'PC+手机+iPad多端通用'},
      {id:2,money:'￥299.9',spanone:'积分包2799积分',spantwo:'￥299.9元/2799积分',spanthree:'每积分仅0.109元',spanfour:'充值AI通用积分236积分',spanfive:'全站功能通用',spansix:'充值积分永不过期',spanseven:'多任务同时生成',spaneight:'PC+手机+iPad多端通用'},
    ]);
    //socketio主连接的eff--------------------------//
 useEffect(() => {
   //如果没有token则不执行下去连接socket.io等操作
   if(localStorage.getItem(`aiwebtoken`)==null){
     return
   }
  setSocket(newSocket);
 }, [newSocket])
 useEffect(() => {
   if(socket != null){
      //用户支付成功获取成功信息
      socket.on('userpaysuccess', data => {
         console.log(data);
         //浏览器存储token和用户
         localStorage.setItem('aiwebmemberdate',data.memberdate)
         localStorage.setItem('aiwebmemberintegral',data.memberintegral)
         store.userpayupdate(data.memberdate,data.memberintegral)
         setuserpayisshow(false)
         promptf('支付成功');
     })
   }
 }, [socket]);
    useEffect(() => {
        var unsubscribe=autorun(()=>{
            setrechargeisshow(store.loginisshow)
            setrechargetoisshow(store.userisshow);
            // console.log(store.loginisshow);
            // console.log(store.userisshow);
          })
        return () => {
            unsubscribe()
        };
    }, []);
    const rechargeclosedown=()=>{
      store.userisshowdown(2)
    }
    //点了模型选择
    const modeldown=(index)=>{
      if(index==0){
          setvipaddisshow(true)
          setSlideTo('to-left')
      }else if(index==1){
        setvipaddisshow(false)
          setSlideTo('to-right')
      }
  }
  //点了经常文的问题
  const rechargeissuedown=()=>{
     store.userisshowdown(4)
  }
  //点了去开通
  const goopenit=async(index)=>{
  let parurlsuffix;
  if(paymentmethod=='支付宝'){
   parurlsuffix='payzfbdmfewm'
}else if (paymentmethod=='微信'){
   parurlsuffix='paywxjsapiewm'
}
    //发请求
 try {
   const params2 = new URLSearchParams();
   let usertypetopay='';
   if(vipaddisshow){
      usertypetopay=rechargeuservip[index].spanone
   }else{
      usertypetopay=rechargeuseradd[index].spanone
   }
   params2.append('usertypetopay',usertypetopay );
   axios.defaults.headers["Authorization"] = localStorage.getItem('aiwebtoken')
   const responsetwo =await axios.post(`${axiosurl}/${parurlsuffix}`,params2)
   if(paymentmethod=='支付宝'){
      console.log(responsetwo);
      setQrCodeUrl(responsetwo.data.qrCode)
      setuserpayselect(index)
      setuserpayisshow(true)
   }else if (paymentmethod=='微信'){
      setQrCodeUrl(responsetwo.data.code_url)
      setuserpayselect(index)
      setuserpayisshow(true)
   }
 }catch (error) {
   console.log(error);
   
   if(error.message=='Network Error'){
     promptf('网络问题');
   }else if (error.response && error.response.status === 429) {
      promptf('请求频率过高，请稍后再试！')
     console.log('请求频率过高');
     // 可以在这里添加显示提示信息或者其他处理逻辑
   } else {
     console.log('An error occurred:', error.message);
     // 处理其他类型的错误
   }
 }
  }
  //关闭开通按钮
  const userpaymentclose=()=>{
   setuserpayisshow(false)
  }
  //用户点击了支付方式选择
  const paymentmethoddown=async(item)=>{
   setQrCodeUrl('')
   let parurlsuffix;
   if(item=='支付宝'){
      parurlsuffix='payzfbdmfewm'
   }else if (item=='微信'){
      parurlsuffix='paywxjsapiewm'
   }
       //发请求
    try {
      const params2 = new URLSearchParams();
      let usertypetopay='';
      if(vipaddisshow){
         usertypetopay=rechargeuservip[userpayselect].spanone
      }else{
         usertypetopay=rechargeuseradd[userpayselect].spanone
      }
      params2.append('usertypetopay',usertypetopay );
      axios.defaults.headers["Authorization"] = localStorage.getItem('aiwebtoken')
      const responsetwo =await axios.post(`${axiosurl}/${parurlsuffix}`,params2)
      if(item=='支付宝'){
         console.log(responsetwo);
         setQrCodeUrl(responsetwo.data.qrCode)
      }else if (item=='微信'){
         setQrCodeUrl(responsetwo.data.code_url)
      }
    }catch (error) {
      console.log(error);
      
      if(error.message=='Network Error'){
        promptf('网络问题');
      }else if (error.response && error.response.status === 429) {
         promptf('请求频率过高，请稍后再试！')
        console.log('请求频率过高');
        // 可以在这里添加显示提示信息或者其他处理逻辑
      } else {
        console.log('An error occurred:', error.message);
        // 处理其他类型的错误
      }
    }
   setpaymentmethod(item)
  }
   //提示盒子调用函数
   const promptf=(item)=>{
      setpromptspan(item)
      setpromptisshow(true)
      setTimeout(() => {
        setpromptisshow(false)
      }, 3000);
    }
  return (
    <div  style={{
        display: rechargetoisshow ==3 ? 'block' : 'none',
      }}>
    {/* 登录主体 */}
     {/* 过渡动画1/2 */}
     <CSSTransition
        in={rechargeisshow}              // 控制组件是否显示
        timeout={300}          // 动画时长
        classNames="fade"      // CSS 类名前缀
        unmountOnExit          // 退出时从 DOM 中移除
      >
    <div className='recharge'>
        <div className='loginbox'>
          {/* 提示盒子 */}
     {promptisshow?
      <div className='promptbox'>
        <span>{promptspan}</span>
      </div>
      :null}
             <div className='rechargeclose' onClick={rechargeclosedown}/>
             <div className='onebox'>
                  <span>成为尊贵会员（多端通用）</span>
             </div>
             <div className='twobox'>
              {/* 下面这行时滑块 */}
             <div className={`slider ${slideTo}`}></div>
                <div className='userselect' onClick={modeldown.bind(this,0)}>
                     <span>vip开通</span>
                </div>
                <div className='userselect' onClick={modeldown.bind(this,1)}>
                     <span>加量包</span>
                </div>
             </div>
             <div className='threebox'>
              {vipaddisshow?rechargeuservip.map((item,index)=>(
               <div className='threepaddingboxin' key={index}>
               <div className='threeboxin'>
                <div className='one'>
                   <span>{item.spanone}</span>
                </div>
                <div className='two'>
                   <span>{item.spantwo}</span>
                </div>
                <div className='three'>
                   <span>{item.spanthree}</span>
                </div>
                <div className='four' onClick={goopenit.bind(this,index)}>
                   <span>立即开通</span>
                </div>
                <div className='five'>
                   <div className='spanbox'>
                        <span>√</span>
                        <span>{item.spanfour}</span>
                   </div>
                   <div className='spanbox'>
                        <span>√</span>
                        <span>{item.spanfive}</span>
                   </div>
                   <div className='spanbox'>
                        <span>√</span>
                        <span>{item.spansix}</span>
                   </div>
                   <div className='spanbox'>
                        <span>√</span>
                        <span>{item.spanseven}</span>
                   </div>
                   <div className='spanbox'>
                        <span>√</span>
                        <span>{item.spaneight}</span>
                   </div>
                </div>
                <div className='six' onClick={rechargeissuedown}>
                    <span>充值消费解答</span>
                </div>
               </div>
               </div>
              ))
              :
              rechargeuseradd.map((item,index)=>(
               <div className='threepaddingboxin'>
               <div className='threeboxin'>
                <div className='one'>
                   <span>{item.spanone}</span>
                </div>
                <div className='two'>
                   <span>{item.spantwo}</span>
                </div>
                <div className='three'>
                   <span>{item.spanthree}</span>
                </div>
                <div className='four' onClick={goopenit.bind(this,index)}>
                   <span>立即开通</span>
                </div>
                <div className='five'>
                   <div className='spanbox'>
                        <span>√</span>
                        <span>{item.spanfour}</span>
                   </div>
                   <div className='spanbox'>
                        <span>√</span>
                        <span>{item.spanfive}</span>
                   </div>
                   <div className='spanbox'>
                        <span>√</span>
                        <span>{item.spansix}</span>
                   </div>
                   <div className='spanbox'>
                        <span>√</span>
                        <span>{item.spanseven}</span>
                   </div>
                   <div className='spanbox'>
                        <span>√</span>
                        <span>{item.spaneight}</span>
                   </div>
                </div>
                <div className='six' onClick={rechargeissuedown}>
                    <span>充值消费解答</span>
                </div>
               </div>
               </div>
              ))
              }
             </div>
             {/* 生成二维码页面 */}
             <CSSTransition
               in={userpayisshow}              // 控制组件是否显示
               timeout={300}          // 动画时长
               classNames="fade"      // CSS 类名前缀
               unmountOnExit          // 退出时从 DOM 中移除
             >
             <div className='userpayment'>
                 <div className='userpaymentin'>
                     <div className='userpaymentclose' onClick={userpaymentclose}/>
                     <div className='userpaybox'>
                        <span>订阅类型：</span>
                        <span>{vipaddisshow?rechargeuservip[userpayselect].spanone:rechargeuseradd[userpayselect].spanone}</span>
                     </div>
                     <div className='userpaybox'>
                        <span>支付金额：</span>
                        <span>{vipaddisshow?rechargeuservip[userpayselect].money:rechargeuseradd[userpayselect].money}</span>
                     </div>
                     <div className='userpaybox'>
                        <span>支付方式：</span>
                        <div className={paymentmethod==='支付宝'?'payway down':'payway'} onClick={paymentmethoddown.bind(this,'支付宝')}>
                           <AlipayCircleOutlined style={{ fontSize: '19px', color:'#1677ff'}}/>
                           <span>支付宝</span>
                        </div>
                        <div className={paymentmethod==='微信'?'payway down':'payway'} onClick={paymentmethoddown.bind(this,'微信')}>
                           <WechatOutlined style={{ fontSize: '19px', color:'#07C160'}}/>
                           <span>微信</span>
                        </div>
                     </div>
                     <div className='erweima'>
                        <div className='imgbox'>
                              {qrCodeUrl && 
                                 <QRCodeCanvas value={qrCodeUrl} size={140} />
                              }
                        </div>
                     </div>
                     <div className='erweimatext'>
                        <span>{paymentmethod}‘扫一扫’支付</span>
                     </div>
                     <div className='userpaybutton' onClick={userpaymentclose}>
                        <button>我已支付</button>
                        <button>取消</button>
                     </div>
                 </div>
             </div>
             </CSSTransition>
        </div>
    </div>
    </CSSTransition>
     </div>
  )
}
