// //不采用懒加载
// import React, { useEffect, useState, useMemo } from "react";
// import { useRoutes, Navigate } from "react-router-dom";
// import './LoadingSpinner.css';
// import Video from "../views/video/Video";
// import Draw from "../views/draw/Draw";
// import Music from "../views/music/Music";
// import Chatai from "../views/chatai/Chatai";
// export default function Router2() {
//   const [windowwidth, setwindowwidth] = useState(); // 保存当前屏幕的宽

//   // 用于监听当前屏幕的宽
//   useEffect(() => {
//     setwindowwidth(window.innerWidth);
//     const handleResize = () => {
//       setwindowwidth(window.innerWidth);
//     };
//     window.addEventListener('resize', handleResize);

//     // 清理事件监听器
//     return () => {
//       window.removeEventListener('resize', handleResize);
//     };
//   }, []);

//   // 路由配置
//   const routes = useMemo(() => [
//     { path: "*", element: <Navigate to="/draw" replace /> },
//     { path: '/video', element: <Video></Video> },
//     { path: '/draw', element:  <Draw></Draw>  },
//     { path: "/chatai", element: <Chatai></Chatai> },
//     { path: "/music", element:  <Music></Music> },
//   ], [windowwidth]);

//   const element = useRoutes(routes);

//   return element;
// }

// // 自定义加载组件
// const LoadingSpinner = ({ windowwidth }) => (
//   <>
//     {windowwidth > 750 ? (
//       <div className='LoadingSpinner'>
//         <div className='left'>
//           <div className='logobox'></div>
//         </div>
//         <div className='right'></div>
//       </div>
//     ) : (
//       <div className='LoadingSpinnertwo'></div>
//     )}
//   </>
// );

// // 缓存懒加载组件的封装
// const cache = new Map(); // 缓存对象

// const lazyLoadWithCache = (path, windowwidth) => {
//   if (!cache.has(path)) {
//     // 只有在缓存中没有时才创建新的 Lazy 组件
//     const Comp = React.lazy(() => import(`../views/${path}`));
//     cache.set(path, Comp);
//   }

//   const CachedComponent = cache.get(path);

//   return (
//     <React.Suspense fallback={<LoadingSpinner windowwidth={windowwidth} />}>
//       <CachedComponent />
//     </React.Suspense>
//   );
// }
////////////////////////////这个是懒加载存储已下载组件
//防止页面切换再返回后导致再次下载页面的方式
import React, { useEffect, useState, useMemo } from "react";
import { useRoutes, Navigate,useLocation  } from "react-router-dom";
import './LoadingSpinner.css';
//npm install react-transition-group过渡动画1/4
import { TransitionGroup, CSSTransition } from "react-transition-group";
import './transitions.css'; // 存放过渡动画的样式
export default function Router2() {
  const location = useLocation(); // 获取当前的路由位置，过渡动画2/4
  const [windowwidth, setwindowwidth] = useState(); // 保存当前屏幕的宽

  // 用于监听当前屏幕的宽
  useEffect(() => {
    setwindowwidth(window.innerWidth);
    const handleResize = () => {
      setwindowwidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    // 清理事件监听器
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // 路由配置
  const routes = useMemo(() => [
    { path: "*", element: <Navigate to="/draw" replace /> },
    { path: '/', element: lazyLoadWithCache('draw/Draw', windowwidth) },
    { path: '/video', element: lazyLoadWithCache('video/Video', windowwidth) },
    { path: '/draw', element: lazyLoadWithCache('draw/Draw', windowwidth) },
    { path: "/chatai", element: lazyLoadWithCache('chatai/Chatai', windowwidth) },
    { path: "/music", element: lazyLoadWithCache('music/Music', windowwidth) },
  ], [windowwidth]);

  const element = useRoutes(routes);

  // return element;
  // 过渡动画的写法，过渡动画3/4
  return (
    <TransitionGroup component={null}>
      <CSSTransition
        key={location.pathname} // 使用路径名作为唯一 key
        classNames="fade" // 引入 CSS 动画
        timeout={300} // 动画持续时间
      >
        {element}
      </CSSTransition>
    </TransitionGroup>
  );
}

// 自定义加载组件
const LoadingSpinner = ({ windowwidth }) => (
  <>
    {windowwidth > 750 ? (
      <div className='LoadingSpinner'>
        
      </div>
    ) : (
      <div className='LoadingSpinnertwo'></div>
    )}
  </>
);

// 缓存懒加载组件的封装
const cache = new Map(); // 缓存对象

const lazyLoadWithCache = (path, windowwidth) => {
  if (!cache.has(path)) {
    // 只有在缓存中没有时才创建新的 Lazy 组件
    const Comp = React.lazy(() => import(`../views/${path}`));
    cache.set(path, Comp);
  }

  const CachedComponent = cache.get(path);
// 改为支持过渡动画的写法，过渡动画4/4
  return (
    <TransitionGroup component={null}>
    <React.Suspense fallback={
      <CSSTransition in={true} appear={true} timeout={300} classNames="fade" unmountOnExit>
        <LoadingSpinner windowwidth={windowwidth} />
      </CSSTransition>
    }>
      <CSSTransition in={true} appear={true} timeout={300} classNames="fade" unmountOnExit>
        <CachedComponent />
      </CSSTransition>
    </React.Suspense>
  </TransitionGroup>
  );
}
////////////////////////////这个是懒加载但不存储已下载组件
// import React,{useEffect,useState } from "react";
// import { useRoutes, Navigate} from "react-router-dom";
// import'./LoadingSpinner.css'
// export default function Router2() {
//   const [windowwidth, setwindowwidth] = useState();//保存当前屏幕的宽
//    //用于监听当前屏幕的宽
//    useEffect(() => {
//     setwindowwidth(window.innerWidth)
//     const handleResize = () => {
//       setwindowwidth(window.innerWidth);
//     };

//     window.addEventListener('resize', handleResize);

//     // 清理事件监听器
//     return () => {
//       window.removeEventListener('resize', handleResize);
//     };
//   }, []);
//   const element = useRoutes([
//     {
//       path: "*",
//       element: Lazyload('draw/Draw',windowwidth),
//     },
//     {
//       path:'/video',
//       element:Lazyload('video/Video',windowwidth),
//     },
//     {
//       path:'/draw',
//       element:Lazyload('draw/Draw',windowwidth),
//     },
//     {
//       path: "/chatai",
//       element: Lazyload('chatai/Chatai',windowwidth),
//     },
//     {
//       path: "/music",
//       element: Lazyload('music/Music',windowwidth),
//     },
//   ]);
//   return element;
// }
//  // 自定义加载组件
//  const LoadingSpinner = ({windowwidth}) => (
//   <>
//   {
//     windowwidth>750?
//     <div className='LoadingSpinner'>
//       <div className='left'>
//         <div className='logobox'></div>
//       </div>
//        <div className='right'></div>
//     </div>
//         :
//         <div className='LoadingSpinnertwo'>
//       </div>
//     }
//        </>
     
 
// );
// //路由懒加载的封装
// const Lazyload = (path,windowwidth) => {
//     //配置默认路径
//     const Comp = React.lazy(() => import(`../views/${path}`));
//     return (
//       <React.Suspense fallback={<LoadingSpinner windowwidth={windowwidth}/>}>
//         <Comp></Comp>
//       </React.Suspense>
//     );
//   };
