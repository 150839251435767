import React,{useState,useEffect} from 'react'
import './Rechargeissue.css'
import store from '../../../mobx/store';
import { autorun, runInAction } from 'mobx';
//npm install react-transition-group过渡动画1/2
import { TransitionGroup, CSSTransition } from "react-transition-group";
import '../../../transitions/transitions.css'; // 存放过渡动画的样式
export default function Recharge() {
    const [rechargeisshow, setrechargeisshow] = useState(false);//控制用户充值页显示和隐藏
    const [rechargetoisshow, setrechargetoisshow] = useState(false);//用于控制点击topbar后显示用户登录式样还是注册登录等式样
    useEffect(() => {
        var unsubscribe=autorun(()=>{
            setrechargeisshow(store.loginisshow)
            setrechargetoisshow(store.userisshow);
            // console.log(store.loginisshow);
            // console.log(store.userisshow);
          })
        return () => {
            unsubscribe()
        };
    }, []);
    const rechargeclosedown=()=>{
      store.userisshowdown(3)
    }
  return (
    <div  style={{
        display: rechargetoisshow ==4 ? 'block' : 'none',
      }}>
    {/* 登录主体 */}
     {/* 过渡动画1/2 */}
     <CSSTransition
        in={rechargeisshow}              // 控制组件是否显示
        timeout={300}          // 动画时长
        classNames="fade"      // CSS 类名前缀
        unmountOnExit          // 退出时从 DOM 中移除
      >
    <div className='rechargeissue'>
        <div className='loginbox'>
             <div className='rechargeclose' onClick={rechargeclosedown}/>
             <div className='onebox'>
                  <span>常见问题解答</span>
             </div>
             <div className='twobox'>
                 <div className='issuespanbox'>
                    <span>1、积分目前消耗定价？</span>
                    <span>1.chatgpt3.5 消耗积分 0.1<br/>
                    2.chatgpt4.0 消耗积分 0.7<br/>
                    3.生成歌词 消耗积分 0.2<br/>
                    4.创作歌曲 消耗积分 1.5<br/>
                    5.延长歌曲 消耗积分 1.5<br/>
                    6.重制歌曲 消耗积分 1.5<br/>
                    7.拼接歌曲 消耗积分 0.2<br/>
                    8.pika生成视频 消耗积分 3<br/>
                    9.pika延长视频 消耗积分 3<br/>
                    10.pika重制视频 消耗积分 3<br/>
                    11.pika高清视频 消耗积分 3<br/>
                    12.relax绘图 消耗积分 1.5 fast绘图 消耗积分 3<br/>
                    13.relax放大 消耗积分 1.5 fast放大 消耗积分 1.5<br/>
                    14.relax变化 消耗积分 1.5 fast变化 消耗积分 3<br/>
                    15.relax扩图 消耗积分 1.5 fast扩图 消耗积分 1.5<br/>
                    16.relax调整 消耗积分 1.5 fast调整 消耗积分 3<br/>
                    17.relax平移 消耗积分 1.5 fast平移 消耗积分 3<br/>
                    18.relax重绘 消耗积分 1.5 fast重绘 消耗积分 3<br/>
                    </span>
                </div>
                <div className='issuespanbox'>
                    <span>2、支持退款吗？</span>
                    <span>本服务提供虚拟信息，一经购买，系统将分配GPU算力资源，且不接受退款申请，请谨慎下单。本站承诺，如因不可抗力因素导致无法提供服务，可按未使用部分折算，并通过原支付渠道退款，确保您的风险为零，您只需支付已使用的部分费用。</span>
                </div>
                <div className='issuespanbox'>
                    <span>3、什么是积分？</span>
                    <span>积分是本平台通用的虚拟货币，积分适用于本平台的所有功能，以及未来推出的所有功能。</span>
                </div>
                <div className='issuespanbox'>
                    <span>4、为什么包月积分会过期？</span>
                    <span>赠送积分会随着会员期限的到期而清零，独立购买的充值积分，是不受时间限制，永不过期的。</span>
                </div>
                <div className='issuespanbox'>
                    <span>5、AI绘画中，什么是“快图模式”，什么是“常规模式”？</span>
                    <span>平台有两种图像生成模式:“快图模式”和“常规模式”，也就是对应MJ模型中，GPU运算的的Fast模式和Relax模式。快图模式将会立即为您提供 GPU运算，这是最高优先级的处理级别，会有点贵。常规模式会根据MJ模型当前使用总人数和绘画任务数，将您的任务排在其他人后面，正常情况下2-5分钟，高峰期会超过10分钟，下午3点半-5点半是高峰期，请注意错峰使用，高峰期建议使用快图模式。</span>
                </div>
                <div className='issuespanbox'>
                    <span>6、会员价为什么这么便宜？</span>
                    <span>MJ模型的会员有三种定价标准，分别是$10（约￥70）/月、$30（约￥210）/月、$60（约￥420）/月，分别对应了大约200张快图/月、900张快图（15个小时）/ 月、1800张快图（30个小时）/月。我们在使用条数上适当做了压缩（因为正常我们根本不需要那么多条），所以定价便宜，可以说是价格最低的了。</span>
                </div>
                <div className='issuespanbox'>
                    <span>7、我想要更多的快图可以吗？</span>
                    <span>除了您的常规订阅外，您还可以选择订购充值积分包，积分可以用来在快速模式下绘画，一次快速绘画将消耗相应积分，充值积分包没有过期时间，直到您使用完。</span>
                </div>
                <div className='issuespanbox'>
                    <span>8、图片生成是由MJ提供的吗？</span>
                    <span>是的，我们采用MJ相同的算法模型，通过GPU算力生成图片，图片质量与国外MJ生成的质量是相同的，请放心使用。</span>
                </div>
                <div className='issuespanbox'>
                    <span>9、服务稳定吗？</span>
                    <span>当前看服务非常稳定，同时本站承诺如因不可抗力因素无法提供服务，会将未使用的条数进行折算，通过支付原路退还给您，让你的风险为零，您仅需要对已使用的部分付费即可。</span>
                </div>
                <div className='issuespanbox'>
                    <span>10、生成的图片为什么有些没有成功？</span>
                    <span>即便您用MJ的官方绘图，也会有3-5%的失败率，原因通常是：短时间内重复提交、有违法违规的指令、参数错误，本站整体的失败率也是3-5%，但失败的不会占用您应享有的额度。</span>
                </div>
                <div className='issuespanbox'>
                    <span>11、本站生成图片的速度和MJ绘画是一样的吗？</span>
                    <span>绘图本身是非常消耗GPU算力（提供算力的服务器很贵的）的，所以快图模式，一般一张图都需要1-3分钟，同时为了合法合规运营，本站做了文本的违规检测和图片审核，速度会稍微慢几秒，也仅有3-10秒的延迟。</span>
                </div>
                <div className='issuespanbox'>
                    <span>12、我的图像可以商用吗？</span>
                    <span>只要是您订阅后自己画的图，您就可以以任何方式自由使用您的图像。</span>
                </div>
                <div className='issuespanbox'>
                    <span>13、支持开发票吗？</span>
                    <span>本站不支持开发票，介意者请谨慎购买！</span>
                </div>
             </div>
        </div>
    </div>
    </CSSTransition>
     </div>
  )
}
